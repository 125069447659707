.tabs {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px 20px;
    cursor: pointer;
    background: #f0f0f0;
    border: none;
    border-radius: 5px;
  }
  
  .tabs button.active {
    background: #5e94f8;
    color: white;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  